import React from 'react'
import PropTypes from 'prop-types'
import { ScrollCTABtn } from './index.style'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import { animation } from '../../styles/vars/animation.style'

const ScrollCTA = ({ onClick, show }) => (
  <ScrollCTABtn onClick={onClick} show={show}>
    <AnimateSlideIn delay={animation.pageEntryDuration} direction="left">
      <IconArrowheadRight responsive />
    </AnimateSlideIn>
  </ScrollCTABtn>
)

ScrollCTA.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
}

export default ScrollCTA
