import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ScrollSection from '../ScrollSection'
import { animation } from '../../styles/vars/animation.style'
import {
  ContactContainer,
  ContactMapBackground,
  ContactMap,
  ContactMapPin,
  ContactIntro,
  ContactIntroTitle,
  ContactIntroText,
  ContactIntroLabel,
  ContactDividerImage,
  ContactMain,
  ContactMainTitle,
  ContactWrapper,
  ContactWrapperText,
  ContactWrapperTitle,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import LinkBlock from '../LinkBlock'
import AnimateImage from '../_animation/AnimateImage'
import RichText from '../RichText'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import InViewSection from '../InViewSection'
import IconMail from '../_svgs/IconMail'
import { ScrollWrapper } from '../Scroll/index.style'
import { colors } from '../../styles/vars/colors.style'
import Latest from '../Latest'
import Map from '../Map'
import ScrollCTA from '../ScrollCTA'
import { scrollTo } from '../../utils/utils'
import MapMarker from './assets/map-marker.svg'
import { isDesktop } from 'react-device-detect'

const Contact = ({ data }) => {
  const {
    introTitle,
    introText,
    contactTitle,
    contactText,
    contactEmailAddress,
    dividerImage,
  } = data
  const entryDelay = animation.pageExitDuration
  const [showArrow, setShowArrow] = useState(true)

  useEffect(() => {
    if (showArrow && isDesktop) {
      window.scroll.on('scroll', args => {
        if (showArrow && args.scroll.x > 300 && args.scroll.x < 400) {
          setShowArrow(false)
        }
      })
    }
  }, [showArrow, setShowArrow])

  return (
    <>
      <ScrollWrapper color={colors.contact}>
        <ScrollSection width={2008}>
          <>
            <ContactContainer>
              <ContactIntro>
                <ContactIntroLabel>
                  <AnimateSlideIn direction="left" delay={entryDelay}>
                    Contact
                  </AnimateSlideIn>
                </ContactIntroLabel>

                <ContactIntroTitle>
                  <AnimateSplitText delay={entryDelay}>
                    {introTitle}
                  </AnimateSplitText>
                </ContactIntroTitle>

                <ContactIntroText>
                  <RichText content={introText} delay={entryDelay + 0.5} />
                </ContactIntroText>
              </ContactIntro>

              <ContactMap>
                <AnimateSlideIn direction="left" delay={entryDelay + 0.6}>
                  <ContactMapBackground>
                    <Map />
                    <ContactMapPin>
                      <AnimateSlideIn delay={entryDelay + 0.4}>
                        <img src={MapMarker} alt="We are here" />
                      </AnimateSlideIn>
                    </ContactMapPin>
                  </ContactMapBackground>
                </AnimateSlideIn>
              </ContactMap>
            </ContactContainer>
          </>
        </ScrollSection>

        <ScrollSection id="contactImage" width={720}>
          <ContactDividerImage>
            <AnimateImage>
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={dividerImage.gatsbyImageData}
                alt={dividerImage.description}
              />
            </AnimateImage>
          </ContactDividerImage>
        </ScrollSection>

        <ScrollSection width={1260} backgroundColor={colors.light2}>
          <ContactMain>
            <ContactMainTitle>
              <AnimateSplitText>{contactTitle}</AnimateSplitText>
            </ContactMainTitle>

            <ContactWrapper>
              <ContactWrapperText>
                <RichText content={contactText} />
              </ContactWrapperText>

              <ContactWrapperTitle>
                <AnimateSlideIn>Contact</AnimateSlideIn>
              </ContactWrapperTitle>

              <AnimateSlideIn>
                <LinkBlock
                  icon={<IconMail />}
                  text={contactEmailAddress}
                  url={`mailto:${contactEmailAddress}`}
                />
              </AnimateSlideIn>
            </ContactWrapper>
          </ContactMain>
        </ScrollSection>

        <ScrollSection id="latest" repeat offset="180">
          <Latest />
        </ScrollSection>
      </ScrollWrapper>

      <InViewSection>
        <ScrollCTA onClick={() => scrollTo('contactImage')} show={showArrow} />
      </InViewSection>
    </>
  )
}

Contact.propTypes = {
  data: PropTypes.object,
}

export default Contact
