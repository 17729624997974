import React, { useRef, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MapMain } from './index.style'

const accessToken =
  'pk.eyJ1IjoiaGVsbG90b3V5ZmlnaHQiLCJhIjoiY2tueWRvYmEyMGpibjJvbzNrZjM2bTRtMSJ9.oEANAm-wxR_75aLmBnpZXg'

const Map = () => {
  const mapRef = useRef(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      accessToken,
      container: mapRef.current,
      style: 'mapbox://styles/hellotouyfight/ckp5n5hg11pgn17pfnqgtqcjp',
      center: [-118.36453048579902, 34.08364937112739],
      zoom: 16.2,
      interactive: false,
    })

    return () => map.remove()
  }, [])

  return <MapMain ref={mapRef} />
}

export default Map
