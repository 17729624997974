import styled from 'styled-components'
import { flow, horizontalPadding } from '../../styles/utils/functions.style'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'

const mapMarkerShadowWidthHorizontal = 86
const mapMarkerShadowHeightHorizontal = 103
export const mapMarkerHeightHorizontal = 74

const mapMarkerShadowWidthMobile = 60
const mapMarkerShadowHeightMobile = 72
export const mapMarkerHeightMobile = 54
export const mapHorizontalOffset = '7rem'

export const ContactContainer = styled.div`
  padding-top: 11.1rem;
  padding-bottom: 6.4rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    padding: 0;
  }
`

export const ContactMap = styled.div`
  ${mq.belowHorizontal} {
    margin-top: 6.4rem;
  }

  ${mq.horizontal} {
    padding-left: ${horizontalScale(132)};
    padding-right: ${horizontalScale(172)};
  }
`

export const ContactMapBackground = styled.div`
  position: relative;
  width: 100%;
  height: 31rem;
  overflow: hidden;

  ${mq.horizontal} {
    width: ${horizontalScale(744)};
    height: ${horizontalScale(781)};
    margin-bottom: ${horizontalScale(114)};
    overflow: hidden;
  }
`

export const ContactMapPin = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: ${mapMarkerShadowWidthMobile / 10}rem;
  height: ${mapMarkerShadowHeightMobile / 10}rem;
  z-index: 1;

  ${mq.horizontal} {
    left: calc(50% - ${mapHorizontalOffset});
    width: ${horizontalScale(mapMarkerShadowWidthHorizontal)};
    height: ${horizontalScale(mapMarkerShadowHeightHorizontal)};
  }

  img {
    width: 100%;
    height: 100%;
  }
`

export const ContactIntro = styled.div`
  ${mq.horizontal} {
    position: relative;
    margin-left: ${horizontalScale(168)};
    padding-bottom: ${horizontalScale(160)};
  }
`

export const ContactIntroLabel = styled.p`
  display: none;

  ${mq.horizontal} {
    position: absolute;
    top: 0;
    left: ${horizontalScale(-24)};
    transform-origin: 0% 0%;
    transform: rotate(90deg);
    display: block;
    font-size: ${horizontalScale(12)};
    line-height: 1;
    text-transform: uppercase;
  }
`

export const ContactIntroTitle = styled.h2`
  font-size: 13.9rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  line-height: 0.82;
  text-transform: uppercase;

  ${mq.horizontal} {
    max-width: ${horizontalScale(723)};
    font-size: ${horizontalScale(280)};
    line-height: 0.84;
  }
`

export const ContactIntroText = styled.div`
  margin-top: 3.8rem;
  ${flow()}

  ${mq.horizontal} {
    max-width: ${horizontalScale(406)};
    margin-top: 0;
  }

  p {
    font-size: 2.4rem;
    font-weight: ${font.weight.light};
    line-height: 1.2;

    ${mq.horizontal} {
      font-size: ${horizontalScale(26)};
      letter-spacing: -0.02em;
    }
  }

  a {
    display: inline-flex;
  }
`

export const ContactDividerImage = styled.div`
  width: 100%;
  height: 100%;
`

export const ContactMain = styled.div`
  ${mq.belowHorizontal} {
    padding-top: 6.6rem;
    padding-bottom: 6.6rem;
    ${horizontalPadding()}
  }

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    padding-left: ${horizontalScale(204)};
    padding-right: ${horizontalScale(308)};
  }
`

export const ContactMainTitle = styled.h2`
  font-size: 11rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  line-height: 0.84;
  text-transform: uppercase;

  ${mq.horizontal} {
    max-width: ${horizontalScale(300)};
    padding-bottom: ${horizontalScale(524)};
    margin-right: ${horizontalScale(60)};
    font-size: ${horizontalScale(180)};
    font-variation-settings: ${font.weight.secondary.extrabold};
  }
`

export const ContactWrapper = styled.div`
  ${mq.belowHorizontal} {
    margin-top: 6.4rem;
  }

  ${mq.horizontal} {
    max-width: ${horizontalScale(400)};
    padding-bottom: ${horizontalScale(250)};
  }
`

export const ContactWrapperText = styled.div`
  ${mq.horizontal} {
    ${flow()}
  }

  p {
    font-size: 2.4rem;
    font-weight: ${font.weight.light};
    line-height: 1.2;
    letter-spacing: -0.02em;

    ${mq.horizontal} {
      font-size: ${horizontalScale(24)};
    }
  }
`

export const ContactWrapperTitle = styled.h3`
  margin-top: 6.4rem;
  margin-bottom: 1.1rem;
  font-size: 2.8rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(60)};
    margin-bottom: ${horizontalScale(18)};
    font-size: ${horizontalScale(28)};
  }
`
