import React from 'react'
import { graphql } from 'gatsby'
import Contact from '../components/Contact'
import SEO from '../components/SEO'

const IndexPage = ({ data }) => {
  const {
    seoDescription,
    title,
    ...contactData
  } = data.allContentfulPageContact.nodes[0]

  return (
    <>
      <SEO title={title} description={seoDescription.seoDescription} />

      <Contact data={contactData} />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query ContactPageQuery {
    allContentfulPageContact {
      nodes {
        title
        seoDescription {
          seoDescription
        }
        introTitle
        introText {
          raw
        }
        dividerImage {
          gatsbyImageData(quality: 80, width: 908, height: 1361)
          description
        }
        contactTitle
        contactText {
          raw
        }
        contactEmailAddress
      }
    }
  }
`
