import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'

export const ScrollCTABtn = styled.button`
  display: none;

  ${mq.horizontal} {
    position: fixed;
    right: ${horizontalScale(44)};
    bottom: ${horizontalScale(110)};
    display: block;
    width: ${horizontalScale(50)};
    border: none;
    background-color: transparent;
    opacity: ${({ show }) => (show ? 1 : 0)};
    pointer-events: ${({ show }) => (show ? undefined : 'none')};
    transition: opacity 0.2s ease;
  }
`
