import React from 'react'

const IconArrowheadRight = ({
  width = 51,
  height = 33,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51 33"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M31.863 0H28.64c0 6.718 2.902 11.663 8.705 14.78H0v3.44h37.344c-5.803 3.117-8.705 8.062-8.705 14.78h3.224c0-4.568 1.612-8.223 4.782-10.857 3.17-2.633 7.684-3.923 13.433-3.923v-3.44c-5.75 0-10.263-1.343-13.433-3.977S31.863 4.568 31.863 0z"
      />
    </svg>
  )
}

export default IconArrowheadRight
