import styled from 'styled-components'
import { horizontalScale, setVh } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'
import {
  mapMarkerHeightMobile,
  mapMarkerHeightHorizontal,
  mapHorizontalOffset,
} from '../Contact/index.style'

export const MapMain = styled.div`
  position: absolute;
  top: calc(50% + ${mapMarkerHeightMobile / 2 / 10}rem);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: calc(100% + 20rem);

  ${mq.horizontal} {
    top: calc(50% + ${horizontalScale(mapMarkerHeightHorizontal / 2)});
    left: calc(50% - ${mapHorizontalOffset});
    ${setVh('height', 100)}
  }
`
